import { render, staticRenderFns } from "./materialTypePop.vue?vue&type=template&id=31661d78"
import script from "./materialTypePop.vue?vue&type=script&lang=js"
export * from "./materialTypePop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\SHMS\\FrontEnd\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31661d78')) {
      api.createRecord('31661d78', component.options)
    } else {
      api.reload('31661d78', component.options)
    }
    module.hot.accept("./materialTypePop.vue?vue&type=template&id=31661d78", function () {
      api.rerender('31661d78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/common/material/materialTypePop.vue"
export default component.exports